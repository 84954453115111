import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import {SelectIcon, MoveIcon, EditIcon, ResizeIcon} from "../../Icons";
import {ControlPanelTools} from "../../../constants/EditorConsts";
import {ControlPanelColors} from "../../../constants/EditorConsts";
import {setCurrTool} from "../../../redux/feature/docTemplateEditorV2";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../../utils";
import "./ControlPanel.scss";

const ControlPanel = React.forwardRef((props, ref) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currTool = useSelector((state) => state.docTemplateEditorV2.currTool);

  const selectModeSwitchOn = () => {
    dispatch(setCurrTool(ControlPanelTools.SELECTING));
    props.onChangeMode();
  };
  const editModeSwitchOn = () => {
    dispatch(setCurrTool(ControlPanelTools.EDITING));
    props.onChangeMode();
  };
  const moveModeSwitchOn = () => {
    dispatch(setCurrTool(ControlPanelTools.MOVING));
    props.onChangeMode();
  };
  const resizeModeSwitchOn = () => {
    dispatch(setCurrTool(ControlPanelTools.RESIZING));
    props.onChangeMode();
  };

  const getToolIconColor = (tool) => {
    return currTool === tool ? ControlPanelColors.SELECT : ControlPanelColors.NOTSELECT;
  };

  return (
    <>
      <Button
        id={"create-area-button"}
        title={capitalizeFirstLetter(t("control-panel.create-area"))}
        className={
          "p-2 control-panel__select-button " +
          (currTool === ControlPanelTools.SELECTING ? "btn-active" : "")
        }
        onClick={selectModeSwitchOn}
      >
        <SelectIcon
          width="18"
          height="18"
          fill={getToolIconColor(ControlPanelTools.SELECTING)}
        />
      </Button>
      <Button
        title={capitalizeFirstLetter(t("control-panel.select-area"))}
        className={
          "p-2 control-panel__edit-button " +
          (currTool === ControlPanelTools.EDITING ? "btn-active" : "")
        }
        onClick={editModeSwitchOn}
      >
        <EditIcon
          width="18"
          height="18"
          fill={getToolIconColor(ControlPanelTools.EDITING)}
        />
      </Button>
      <Button
        title={capitalizeFirstLetter(t("control-panel.resize-area"))}
        className={
          "p-2 control-panel__resize-button " +
          (currTool === ControlPanelTools.RESIZING ? "btn-active" : "")
        }
        onClick={resizeModeSwitchOn}
      >
        <ResizeIcon
          width="18"
          height="18"
          fill={getToolIconColor(ControlPanelTools.RESIZING)}
        />
      </Button>
      <Button
        title={capitalizeFirstLetter(t("control-panel.move-area"))}
        className={
          "p-2 control-panel__move-button " +
          (currTool === ControlPanelTools.MOVING ? "btn-active" : "")
        }
        onClick={moveModeSwitchOn}
      >
        <MoveIcon
          width="18"
          height="18"
          fill={getToolIconColor(ControlPanelTools.MOVING)}
        />
      </Button>
    </>
  );
});

export default ControlPanel;
