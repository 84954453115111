import React, {useState, useEffect, useCallback} from "react";
import CloseIcon from "./CLoseIcon";
import Pointer from "./Pointer";
import TargetInnerCircles from "./TargetInnerCircles";
import TargetCircle from "./TargetCircle";
import HelperText from "./HelperText";
import "./WizardLayout.scss";
import {isMobile, isTablet} from "react-device-detect";

/**
 *
 * @param {string} radius - Радиус круга на таргетом в пикселях/процентах
 * радиус в процентах = 50%, равен диаметру круга в 100%. % от размера targetNode
 * @param {number} xHelperText - х координата в процентах от viewSize для helperText
 * @param {number} yHelperText - y координата в процентах от viewSize для helperText
 * @param {ref} targetNode - реф таргета
 * @param {ref} pointerNode - реф элемента на котором должен быть поинтер
 * @param {Array} helperTextList - массив с текстами помощника
 * @param {Boolean} isLastAction - последнее действие визарда?
 * @param {Function} onNextStep - callback для следующего действия визарда
 */

const WizardLayout = ({
  children,
  targetNode,
  disableAllArea,
  pointerNode,
  radius,
  helperTextList,
  xHelperText,
  yHelperText,
  isLastAction,
  onNextStep: nextStep,
}) => {
  const isNonDesktop = isMobile || isTablet;
  // show wizard or not state
  const [showWizard, setShowWizard] = useState(true);
  //show inner circle, helperTextList, nextButton, skipTutorialButton and pointer or not
  const [showWizardAttr, setShowWizardAttr] = useState(false);

  //for main circle animation
  const [innerRadius, setInnerRadius] = useState(1000);
  // for pointer animation status
  const [pointerAnimationEnd, setPointerAnimationEnd] = useState(true);

  // pointer coordinates-----------------------------------------------------
  const [pointerXAfterAnimation, setPointerXAfterAnimation] = useState(0);
  const [pointerYAfterAnimation, setPointerYAfterAnimation] = useState(0);
  const [pointerX, setPointerX] = useState(0);
  const [pointerY, setPointerY] = useState(0);
  //--------------------------------------------------------------------------

  // main circle coordiantes
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  //--------------------------------------------------------------------------

  // document height and width for setting initial and current svg height and width
  const [documentHeight, setDocumentHeight] = useState(0);
  const [documentWidth, setDocumentWidth] = useState(0);
  const [initialDocumentHeight, setInitialDocumentHeight] = useState(0);
  const [initialDocumentWidth, setInitialDocumentWidth] = useState(0);
  //---------------------------------------------------------------------------

  const skipTutorial = () => {
    //skip tutorial
    setShowWizard(false);
    localStorage.setItem("parsio.skipWizardStatus", true);
  };

  const mainCircleTarget = targetNode;
  const pointerTarget = pointerNode;

  const calculateMainCircleAndPointerCoordinates = useCallback(() => {
    if (mainCircleTarget) {
      const x =
        window.scrollX +
        mainCircleTarget.getBoundingClientRect().left +
        mainCircleTarget.getBoundingClientRect().width / 2;
      const y =
        window.scrollY +
        mainCircleTarget.getBoundingClientRect().top +
        mainCircleTarget.getBoundingClientRect().height / 2;
      setX(x);
      setY(y);

      setInnerRadius(1000);
    }

    if (pointerTarget) {
      const x =
        window.scrollX +
        pointerTarget.getBoundingClientRect().left +
        pointerTarget.getBoundingClientRect().width -
        pointerTarget.getBoundingClientRect().width / 10;
      const y =
        window.scrollY +
        pointerTarget.getBoundingClientRect().top +
        pointerTarget.getBoundingClientRect().height / 1.5;
      setPointerXAfterAnimation(x);
      setPointerYAfterAnimation(y);
      setPointerX(0);
      setPointerY(0);
    }
  }, [mainCircleTarget, pointerTarget]);

  const calculateSVGSizeAndWizardAttributesCoordinates = useCallback(() => {
    setDocumentHeight(document.documentElement.clientHeight);
    setDocumentWidth(document.documentElement.clientWidth);

    calculateMainCircleAndPointerCoordinates();
  }, [calculateMainCircleAndPointerCoordinates]);

  // const debouncedCalculateSVGSizeAndWizardAttributesCoordinates = debounce(
  //   calculateSVGSizeAndWizardAttributesCoordinates,
  //   0
  // );

  // set svg size and wizard atrributes coordinates-----------------------------
  useEffect(() => {
    calculateSVGSizeAndWizardAttributesCoordinates();
  }, [calculateSVGSizeAndWizardAttributesCoordinates]);
  //----------------------------------------------------------------------------

  // check if window has been resized-------------------------------------------
  useEffect(() => {
    window.addEventListener("resize", calculateSVGSizeAndWizardAttributesCoordinates);

    return function cleanUp() {
      window.removeEventListener(
        "resize",
        calculateSVGSizeAndWizardAttributesCoordinates
      );
    };
  }, [calculateSVGSizeAndWizardAttributesCoordinates]);
  //----------------------------------------------------------------------------

  // layout animation-----------------------------------------------------------
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    //first render radius === '0px' via useState("0px")
    if (radius === "0px") {
      return;
    }

    const convertRadiusSize = (radius) => {
      if (radius.includes("%")) {
        return (
          (Math.sqrt(targetNode?.clientWidth ** 2 + targetNode?.clientHeight ** 2) /
            100) *
          Number(radius.slice(0, radius.length - 1))
        );
      }
      if (radius.includes("px")) {
        return Number(radius.slice(0, radius.length - 2));
      } else {
        throw new Error("set correct type of radius size");
      }
    };
    try {
      //check if radius is correct type
      convertRadiusSize(radius);
      //--------------------------------

      if (
        innerRadius > convertRadiusSize(radius) &&
        innerRadius - convertRadiusSize(radius) > 50
      ) {
        // window.requestAnimationFrame(() =>
        setInnerRadius((innerRadius) => innerRadius - 50);
        // );
        return;
      }
      if (
        innerRadius > convertRadiusSize(radius) &&
        innerRadius - convertRadiusSize(radius) > 30
      ) {
        // window.requestAnimationFrame(() => {
        setInnerRadius((innerRadius) => innerRadius - 10);
        // });
        return;
      }

      setShowWizardAttr(true);
    } catch (err) {
      console.log(err);
    }
  }, [innerRadius, radius, targetNode, documentWidth, documentHeight]);
  //----------------------------------------------------------------------------

  // set initial document size - used disable animation if window has been resized
  useEffect(() => {
    setInitialDocumentHeight(document.documentElement.clientHeight);
    setInitialDocumentWidth(document.documentElement.clientWidth);
  }, []);
  //----------------------------------------------------------------------------

  // pointer animation----------------------------------------------------------
  useEffect(() => {
    //check if window size changed
    if (
      initialDocumentHeight !== documentHeight ||
      initialDocumentWidth !== documentWidth
    ) {
      setPointerX(pointerXAfterAnimation);
      setPointerY(pointerYAfterAnimation);
      return;
    }
    //animation activate
    if (showWizardAttr) {
      setPointerAnimationEnd(false);
      const animationXStep = pointerXAfterAnimation / 100;
      const animationYStep = pointerYAfterAnimation / 100;

      if (
        pointerX < pointerXAfterAnimation - animationXStep ||
        pointerY < pointerYAfterAnimation - animationYStep
      ) {
        setPointerX((pointerX) => pointerX + animationXStep);
        setPointerY((pointerY) => pointerY + animationYStep);
      } else {
        setPointerAnimationEnd(true);
      }
    }
  }, [
    pointerX,
    pointerY,
    pointerXAfterAnimation,
    pointerYAfterAnimation,
    showWizardAttr,
    initialDocumentWidth,
    initialDocumentHeight,
    documentHeight,
    documentWidth,
  ]);
  //----------------------------------------------------------------------------

  // check show wizzard attr or not---------------------------------------------
  useEffect(() => {
    if (!targetNode) {
      setShowWizardAttr(false);
    }
  }, [targetNode]);
  //----------------------------------------------------------------------------

  // check show wizard or not---------------------------------------------------
  const wizardSkipStatus = localStorage.getItem("parsio.skipWizardStatus");
  useEffect(() => {
    if (wizardSkipStatus) {
      setShowWizard(false);
    }
  }, [wizardSkipStatus]);
  //----------------------------------------------------------------------------

  // eslint-disable-next-line react-hooks/exhaustive-deps

  if (!showWizard || isNonDesktop || !targetNode || !radius) {
    return (
      <div className="wizard">
        <div className="wizard__content">{children}</div>
      </div>
    );
  }

  if (disableAllArea) {
    return (
      <div className="wizard">
        <div className="wizard__content-disable-all-area"></div>
        <div className="wizard__content">{children}</div>
      </div>
    );
  }

  return (
    <div className="wizard">
      <div className="wizard__content">{children}</div>
      <svg
        className="wizard__overlay"
        width={documentWidth}
        height={documentHeight}
        viewBox={`0 0 ${documentWidth} ${documentHeight}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {showWizardAttr ? (
          <>
            <Pointer cx={pointerX} cy={pointerY} isShow={pointerNode} />
            <TargetInnerCircles
              className="wizard__circle"
              cx={x}
              cy={y}
              innerRadius={innerRadius}
            />
          </>
        ) : null}
        <TargetCircle
          className="wizard__circle"
          cx={x}
          cy={y}
          innerRadius={innerRadius}
          targetNode={targetNode}
          isShow={Boolean(x && y)}
        />
      </svg>
      {showWizardAttr ? (
        <>
          <CloseIcon onClick={skipTutorial} />
          <HelperText
            cx={xHelperText}
            cy={yHelperText}
            isPointerAnimationEnd={pointerAnimationEnd}
            helperTextList={helperTextList}
            isLastAction={isLastAction}
            onNextStep={nextStep}
            onSkipTutorial={skipTutorial}
          />
        </>
      ) : null}
    </div>
  );
};

export default WizardLayout;
