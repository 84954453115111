/* global gapi */
import React, {useState, useEffect} from "react";
import {useFormik} from "formik";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {registerUser, googleAuth} from "../../redux/feature/authV2/actions";
import {SignUpScheme} from "../../formik/validate";
import {isMobile, isTablet} from "react-device-detect";
import NonDesktopSignUpComponent from "./NonDesktop/SignUp";
import DesktopSignUpComponent from "./Desktop/SignUp";
import {useGlobalTheming} from "../../hooks/useGlobalTheming";
import "./Forms.scss";

const PRIVACY_URL =
  "https://drive.google.com/file/d/1kLmUEHc1TuGs8G9VWxfChSJMIeToFexR/view?usp=sharing";

const SignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isNonDesktop = isMobile || isTablet;
  useGlobalTheming();

  const [apiErrors, setApiErrors] = useState({
    email: "",
    password1: "",
    password2: "",
    business_domain: "",
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password1: "",
      password2: "",
      business_domain: "other",
      language: window.navigator.language === "ru-RU" ? "ru" : "en",
    },
    validationSchema: SignUpScheme,
    onSubmit: () => {
      const values = {...formik.values};
      const {grecaptcha} = window;

      //reCAPTCHA
      const siteKey =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PROD
          : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_LOCAL;

      grecaptcha.ready(function () {
        grecaptcha
          .execute(siteKey, {action: "submit"})
          .then(function (token) {
            dispatch(registerUser({...values, recaptcha_response: token})).then(
              (data) => {
                if (data.error) {
                  setApiErrors(data.payload);
                }
              }
            );
          })
          .catch((err) => console.log(err));
      });
    },
  });

  const goToSignIn = () => {
    history.push("/signin");
  };

  const googleAuthHandler = () => {
    gapi.auth2.authorize(
      {
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "email profile openid",
        response_type: "code",
      },
      function (response) {
        if (response.error) {
          console.log("gapiAuthError:", response.error);
          return;
        }

        const code = response.code;

        dispatch(googleAuth({code, redirectUri: window.location.origin}));
      }
    );
  };

  useEffect(() => {
    const {gapi} = window;
    gapi.load("auth2", null);
  }, []);

  return isNonDesktop ? (
    <NonDesktopSignUpComponent
      formik={formik}
      apiErrors={apiErrors}
      privacyUrl={PRIVACY_URL}
      onGoogleAuth={googleAuthHandler}
      onGoToSignIn={goToSignIn}
    />
  ) : (
    <DesktopSignUpComponent
      formik={formik}
      apiErrors={apiErrors}
      privacyUrl={PRIVACY_URL}
      onGoogleAuth={googleAuthHandler}
      onGoToSignIn={goToSignIn}
    />
  );
};

export default SignUp;
