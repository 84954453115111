import React, {useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {Route, Switch, Redirect} from "react-router-dom";
import EditPage from "./pages/Edit";
import New from "./pages/New";
import SupportMobileVersion from "./pages/MobilePages/SupportMobileVersion";
import InstructionListMobileVersion from "./pages/MobilePages/InstructionMobileVersion/InstructionListMobileVersion";
import InstructionPagesMobileVersion from "./pages/MobilePages/InstructionMobileVersion/InstructionPagesMobileVersion";
import RecognizePage from "./pages/Recognition/Recognize";
import RecognizePageID from "./pages/Recognition/RecognizeID";
import RecognitionResults from "./pages/Recognition/Results/Results";
import SignIn from "./pages/Forms/SignIn";
import SignUp from "./pages/Forms/SignUp";
import Templates from "./pages/Templates/Templates";
import {useSelector, useDispatch} from "react-redux";
import {getUserData} from "./redux/feature/authV2/actions";
import Home from "./pages/Home";
// import Test from "./pages/Test";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./locale/en.json";
import ru from "./locale/ru.json";
import PasswordRecover from "./pages/Forms/PasswordRecover";
import PasswordReset from "./pages/Forms/PasswordReset";
import {HotKeys} from "react-hotkeys";
import {clickButtonByHotKey} from "./utils";

import ModalRoot from "./modules/modals/components/ModalRoot";
import ToastsRoot from "./modules/toasts/components/ToastsRoot";

import {isMobile, isTablet} from "react-device-detect";

const PrivateRoute = (props) => {
  if (props.isLoggedIn) {
    return <Route {...props} />;
  }
  return <Redirect to="/signin" />;
};

// Localization init---------------------------------------------
i18n.use(initReactI18next).init({
  resources: {
    en,
    ru,
  },
  fallbackLng: "en",
  // lng: localStorage.getItem("docRecognizer.language") || "en", на случай если вернемся к выбору языка
  lng: window.navigator.language === "ru-RU" ? "ru" : "en",

  interpolation: {
    escapeValue: false,
  },
});

const changeLocale = async (locale) => {
  await i18n.changeLanguage(locale);
};
window.changeLocale = changeLocale;

//----------------------------------------------------------------

//Здесь указываются привязка сочетания клавиш к определенному действию
//Обрати внимание, что для символов надо указывать вариант в обеих раскладках, чтобы работало при любой из них
const keyMap = {
  TOGGLE_MARK: ["ctrl+shift+a", "ctrl+shift+ф"],
};

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authV2.isLoggedIn);

  //Тут на действие привязывается конкретная функция, которая будет вызываться
  //Механизм для нажатия простой и банальный, кнопке в коде даётся специфический id
  //Кнопка ищётся в DOM дереве и если есть - то вызывается её метод .click()
  const handlers = {
    TOGGLE_MARK: clickButtonByHotKey("create-area-button"),
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserData());
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    // captcha script-----------------------------------------------------------
    const siteKey =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PROD
        : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_LOCAL;
    const reCAPTCHAscript = document.createElement("script");

    reCAPTCHAscript.setAttribute(
      "src",
      `https://www.google.com/recaptcha/api.js?render=${siteKey}`
    );
    document.head.appendChild(reCAPTCHAscript);
    //--------------------------------------------------------------------------

    //google auth script -------------------------------------------------------
    const googleAuthScript = document.createElement("script");
    googleAuthScript.src = "https://apis.google.com/js/platform.js?onload=init";
    googleAuthScript.defer = true;

    googleAuthScript.onload = () => {
      const {gapi} = window;
      gapi.load("auth2", null);
    };

    document.head.appendChild(googleAuthScript);

    //--------------------------------------------------------------------------
  }, []);

  useEffect(() => {
    //hide chat icon on mobile version

    let hideChatIconTimer = setInterval(hideChatIcon, 100);

    function hideChatIcon() {
      const chatIcon = document.getElementById("gs");
      if (chatIcon) {
        clearInterval(hideChatIconTimer);

        if (isMobile || isTablet) {
          chatIcon.style.visibility = "hidden";
        }
      }
    }
  });

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <ModalRoot />
      <ToastsRoot />
      <Router>
        <Switch>
          <PrivateRoute exact path="/" isLoggedIn={isLoggedIn} component={Home} />
          <PrivateRoute exact path="/new" isLoggedIn={isLoggedIn} component={New} />
          <PrivateRoute
            exact
            path="/templates"
            isLoggedIn={isLoggedIn}
            component={Templates}
          />
          <PrivateRoute
            exact
            path="/recognition/recognize"
            isLoggedIn={isLoggedIn}
            component={RecognizePage}
          />
          <PrivateRoute
            exact
            path="/recognition/recognize/:docID"
            isLoggedIn={isLoggedIn}
            component={RecognizePageID}
          />
          <PrivateRoute
            exact
            path="/recognition/results"
            isLoggedIn={isLoggedIn}
            component={RecognitionResults}
          />
          <PrivateRoute
            exact
            path="/edit/:tplId"
            isLoggedIn={isLoggedIn}
            component={EditPage}
          />
          <PrivateRoute
            exact
            path="/support"
            isLoggedIn={isLoggedIn}
            component={SupportMobileVersion}
          />
          <PrivateRoute
            exact
            path="/instructions"
            isLoggedIn={isLoggedIn}
            component={InstructionListMobileVersion}
          />
          <PrivateRoute
            exact
            path="/instructions/:id"
            isLoggedIn={isLoggedIn}
            component={InstructionPagesMobileVersion}
          />
          {!isLoggedIn && (
            <>
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/reset" component={PasswordRecover} />
              <Route exact path="/reset/:uid/:token" component={PasswordReset} />
            </>
          )}
          {/* <Route exact path="/test" component={Test} /> */}
          {/*<Route component={NotFound} />*/}
          <Redirect to="/" />
        </Switch>
      </Router>
    </HotKeys>
  );
}

export default App;
