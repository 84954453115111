import * as PDFJS from "pdfjs-dist/build/pdf.js";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import i18next from "i18next";

export const fetchWithToken = (fetch) => (url, config = {}, ...args) => {
  return fetch(
    url,
    {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `token ${localStorage.getItem("token")}`,
      },
    },
    ...args
  );
};

export const convertDataForCSVLink = (data) => {
  const csvData = [[], []];
  data.forEach((result) => {
    csvData[0].push(result.name);
    csvData[1].push(result.text);
  });
  return csvData;
};

export const truncateString = (str, count) => {
  const newStr = str.slice(0, count).trim();
  return str.length > count ? `${newStr}...` : newStr;
};

export const convertMassiveDataForCSVLink = (data) => {
  const csvData = [];

  for (let item of data) {
    const arr1 = [];
    const arr2 = [];
    if (item.texts.length) {
      item.texts.forEach((result) => {
        arr1.push(result.name);
        arr2.push(result.text);
      });
    }
    csvData.push(arr1);
    csvData.push(arr2);
    csvData.push([]); //empty string in csv
  }

  return csvData;
};

export const getDateFromDateTime = (dateTime) => {
  const date = new Date(dateTime).toLocaleDateString("en-GB");
  let editedDate = date.substring(0, 6) + date.substring(8, 10);
  return editedDate;
};

export const getTimeFromDateTime = (dateTime) => {
  const dateTimeObj = new Date(dateTime);
  const hours = dateTimeObj.getHours();
  const minutes = String(dateTimeObj.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const getImageWithAuth = async (url) => {
  const res = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `token ${localStorage.getItem("token")}`,
    },
  });
  const blob = await res.blob();
  return URL.createObjectURL(blob);
};

export const processPdfFile = (file, cb) => {
  const reader = new FileReader();
  PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  reader.onloadend = () => {
    const loadingTask = PDFJS.getDocument(reader.result);

    loadingTask.promise
      .then((pdf) =>
        pdf.getPage(1).then((page) => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          const viewport = page.getViewport({scale: 3});

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {canvasContext: context, viewport: viewport};

          const renderTask = page.render(renderContext);

          renderTask.promise
            .then(() => {
              const image = new Image();
              image.src = canvas.toDataURL();

              image.onload = () => {
                canvas.toBlob((blob) => {
                  if (cb) {
                    cb(image, blob, file.name.slice(0, file.name.length - 3) + "jpg");
                  }
                  canvas.remove();
                });
              };
            })
            .catch(console.log);
        })
      )
      .catch(console.log);
  };
  reader.readAsArrayBuffer(file);
};

export const processImageFile = (file, cb) => {
  const reader = new FileReader();
  const image = new Image();
  reader.onloadend = () => {
    image.src = reader.result;
    image.onload = () => {
      if (cb) {
        cb(image, file, file.name);
      }
    };
  };
  reader.readAsDataURL(file);
};

const formatValue = (value) => {
  return value.toLowerCase().split(/\s+/).join(" ");
};

//Validate input value. Value cannot be empty or (if existing values passed) be in existing values
export const checkValue = (inputValue, existingValues, key) => {
  if (/^ *$/.test(inputValue)) {
    throw new Error(capitalizeFirstLetter(i18next.t("template-editor.empty-value")));
  }

  const compareValues = (value1, value2) => {
    return formatValue(value1) === formatValue(value2);
  };

  if (existingValues) {
    if (key) {
      if (!!existingValues.find((el) => compareValues(el[key], inputValue))) {
        throw new Error(
          capitalizeFirstLetter(i18next.t("template-editor.occupied-value"))
        );
      }
    } else {
      if (!!existingValues.find((el) => compareValues(el, inputValue))) {
        throw new Error(
          capitalizeFirstLetter(i18next.t("template-editor.occupied-value"))
        );
      }
    }
  }
};

//Capitalizes first letters of words in string.
export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );

//Capitalize first letter of string
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Check for file extension and for pdf multi-page
export const checkFile = async (file) => {
  const approvedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
  if (approvedFileTypes.includes(file.type)) {
    if (file.type === "application/pdf") {
      try {
        await checkPdfPageLength(file);
      } catch (err) {
        throw err;
      }
    }
  } else {
    throw new Error(i18next.t("file-load.extension-error"));
  }
};

const checkPdfPageLength = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onloadend = () => {
      const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g)?.length;
      if (count > 1) {
        reject(i18next.t("file-load.multi-page-error"));
      }
      resolve();
    };
  });
};

export const clickButtonByHotKey = (id) => {
  const button = document.getElementById(id);
  if (button) {
    button.click();
  }
};

//check langauge?
export const checkLanguage = (str) => {
  if (/^[a-zA-Z ]+$/.test(str)) {
    return "en";
  } else {
    return "ru";
  }
};
