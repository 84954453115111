import React, {useState, useEffect} from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import {ReactComponent as ForwardArrow} from "../../assets/ForwardArrow.svg";
import {ReactComponent as BackArrow} from "../../assets/BackArrow.svg";
import {useTranslation} from "react-i18next";
import "./UsePagination.scss";
import usePrevious from "../../hooks/usePrevious";

const MIDDLE_PAGE_OFFSET = 2;

//закоментированный код нужен если захотят вернуться к старой логике

const UsePagination = ({
  pageCount,
  currentRequestPage,
  cb,
  children,
  className,
  style,
  ...props
}) => {
  const activePage = currentRequestPage + 1 || 1;
  const {t} = useTranslation();

  //****PART OF CODE NEEDED IN CASES WHEN pageCount > 5*************************
  const [middlePage, setMiddlePage] = useState(activePage + MIDDLE_PAGE_OFFSET);
  // const [isShowLeftSideTools, setIsShowLeftSideTools] = useState(false);
  // const [isShowRightSideTools, setIsShowRightSideTools] = useState(true);

  const handlePageClick = (page) => {
    cb(page - 1);

    // helper function
    const calculateMiddlePageWithRightShiftEqualOne = () => {
      const newMiddlePage = middlePage + 1;

      if (newMiddlePage + MIDDLE_PAGE_OFFSET > pageCount) return;

      setMiddlePage(middlePage + 1);
    };

    // helper function
    const calculateMiddlePageWithLeftShiftEqualOne = () => {
      const newMiddlePage = middlePage - 1;

      if (newMiddlePage - MIDDLE_PAGE_OFFSET <= 0) return;

      setMiddlePage(middlePage - 1);
    };

    //START SHIFTING LOGIC...

    //right hand relative to middle page
    if (page - middlePage === 1) {
      //it means index of pagination pages === 4 (page number 4 in first iteration)

      calculateMiddlePageWithRightShiftEqualOne();
      return;
    }
    if (page - middlePage === 2) {
      //it means index of pagination pages === 5 (page number 5 in first iteration)
      const newMiddlePage = middlePage + 2;

      if (newMiddlePage + MIDDLE_PAGE_OFFSET > pageCount) {
        //try to shift by one
        calculateMiddlePageWithRightShiftEqualOne();
        //if doesn't work - exit function and middlePage not changing
        return;
      }

      setMiddlePage(middlePage + 2);
      return;
    }

    //left hand relative to middle page
    if (middlePage - page === 1) {
      calculateMiddlePageWithLeftShiftEqualOne();
      return;
    }
    if (middlePage - page === 2) {
      //it means index of pagination pages === 1 (page number 1 in first iteration)
      const newMiddlePage = middlePage - 2;

      if (newMiddlePage - MIDDLE_PAGE_OFFSET <= 0) {
        //try to shift by one
        calculateMiddlePageWithLeftShiftEqualOne();
        //if doesn't work - exit function and middlePage not changing
        return;
      }

      setMiddlePage(middlePage - 2);
      return;
    }
    //END SHIFTING LOGIC...
  };

  const handlePreviousPageClick = () => {
    if (activePage - 1 <= 0) return;
    handlePageClick(activePage - 1);
  };

  const handleNextPageClick = () => {
    if (activePage + 1 > pageCount) return;
    handlePageClick(activePage + 1);
  };

  const handleFirstPageClick = () => {
    cb(0);
    setMiddlePage(1 + MIDDLE_PAGE_OFFSET);
    // setIsShowLeftSideTools(false);
    // setIsShowRightSideTools(true);
  };

  const handleLastPageClick = () => {
    cb(pageCount - 1);
    setMiddlePage(pageCount - MIDDLE_PAGE_OFFSET);
    // setIsShowRightSideTools(false);
    // setIsShowLeftSideTools(true);
  };

  // useEffect(() => {
  //   // for unhide/hide LAST and FIRST buttons and NEXT,PREV buttons
  //   if (pageCount > 5) {
  //     if (activePage > pageCount - 2) {
  //       setIsShowRightSideTools(false);
  //     } else {
  //       setIsShowRightSideTools(true);
  //     }
  //     if (activePage <= 3) {
  //       // setIsShowLeftSideTools(false);
  //     } else {
  //       setIsShowLeftSideTools(true);
  //     }
  //   }
  // }, [activePage, pageCount]);

  const prevPageCount = usePrevious(pageCount);

  useEffect(() => {
    // проверяем не изменилось ли количество страниц при удалении и дублицировании
    // шаблонов - если так то нужно middlePage перемещать (двигать окно пагинации)

    if (prevPageCount && pageCount !== prevPageCount) {
      //если количество страниц уменьшилось
      if (pageCount < prevPageCount) {
        // уменьшаем middlePage
        setMiddlePage((middlePage) =>
          middlePage > 3 ? middlePage - (prevPageCount - pageCount) : 3
        );
      }
      //если количество страниц увеличилось
      else {
        //если активная страница находится на первой цифре пагинации,
        //то не двигаем  middlePage, иначе активная страница пропадет.
        // или если активная страница и мидлстраница отличаются на 1
        if (middlePage - activePage !== 2 && activePage - middlePage !== 1) {
          //увеличиваем middlePage
          setMiddlePage((middlePage) => middlePage + (pageCount - prevPageCount));
          //activePage остается без изменений
        }
      }
    }
  }, [activePage, middlePage, pageCount, prevPageCount]);

  //**** END OF PART OF CODE NEEDED IN CASES WHEN pageCount > 5 *************************

  //****PART OF CODE NEEDED IN CASES WHEN pageCount < 5*************************
  const handleClick = (page) => {
    cb(page - 1);
  };

  const paginationItems = [];
  for (let i = 1; i <= pageCount; i++) {
    paginationItems.push(
      <PaginationItem className="usepagination__number" key={i}>
        <PaginationLink
          onClick={() => handleClick(i)}
          className={
            activePage === i
              ? "usepagination__number_active"
              : "usepagination__number_not-active"
          }
        >
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }

  const handleNextClick = () => {
    if (activePage + 1 > pageCount) return;
    handleClick(activePage + 1);
  };

  const handlePreviousClick = () => {
    if (activePage - 1 === 0) return;
    handleClick(activePage - 1);
  };

  const handleLastClick = () => {
    cb(pageCount - 1);
  };

  const handleFirstClick = () => {
    cb(0);
  };

  return (
    <div style={style} className={`usepagination ${className}`} {...props}>
      {children}
      {isFinite(pageCount) && pageCount > 5 && (
        <Pagination size="sm" className="pt-3" style={{justifyContent: "flex-start"}}>
          {
            /*isShowLeftSideTools ? */ <>
              <PaginationItem>
                <PaginationLink
                  className="usepagination__egde"
                  onClick={handleFirstPageClick}
                >
                  {t("pagination.first").toLocaleUpperCase()}
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  className="usepagination__arrow"
                  onClick={handlePreviousPageClick}
                >
                  <BackArrow />
                </PaginationLink>
              </PaginationItem>
            </> /*: null*/
          }
          <PaginationItem className="usepagination__number">
            <PaginationLink
              onClick={() => handlePageClick(middlePage - 2)}
              className={
                activePage === middlePage - 2
                  ? "usepagination__number_active"
                  : "usepagination__number_not-active"
              }
            >
              {middlePage - 2}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="usepagination__number">
            <PaginationLink
              onClick={() => handlePageClick(middlePage - 1)}
              className={
                activePage === middlePage - 1
                  ? "usepagination__number_active"
                  : "usepagination__number_not-active"
              }
            >
              {middlePage - 1}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="usepagination__number">
            <PaginationLink
              onClick={() => handlePageClick(middlePage)}
              className={
                activePage === middlePage
                  ? "usepagination__number_active"
                  : "usepagination__number_not-active"
              }
            >
              {middlePage}
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className="usepagination__number">
            <PaginationLink
              onClick={() => handlePageClick(middlePage + 1)}
              className={
                activePage === middlePage + 1
                  ? "usepagination__number_active"
                  : "usepagination__number_not-active"
              }
            >
              {middlePage + 1}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="usepagination__number">
            <PaginationLink
              onClick={() => handlePageClick(middlePage + 2)}
              className={
                activePage === middlePage + 2
                  ? "usepagination__number_active"
                  : "usepagination__number_not-active"
              }
            >
              {middlePage + 2}
            </PaginationLink>
          </PaginationItem>
          {
            /*isShowRightSideTools ? */ <>
              <PaginationItem>
                <PaginationLink
                  className="usepagination__arrow"
                  onClick={handleNextPageClick}
                >
                  <ForwardArrow />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  className="usepagination__egde"
                  onClick={handleLastPageClick}
                >
                  {t("pagination.last").toLocaleUpperCase()}
                </PaginationLink>
              </PaginationItem>
            </> /*: null*/
          }
        </Pagination>
      )}
      {isFinite(pageCount) && pageCount > 1 && pageCount <= 5 && (
        <Pagination size="sm" className="pt-3" style={{justifyContent: "flex-start"}}>
          <PaginationItem>
            <PaginationLink className="usepagination__egde" onClick={handleFirstClick}>
              {t("pagination.first").toLocaleUpperCase()}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              className="usepagination__arrow"
              onClick={handlePreviousClick}
            >
              <BackArrow />
            </PaginationLink>
          </PaginationItem>

          {paginationItems}
          <PaginationItem>
            <PaginationLink className="usepagination__arrow" onClick={handleNextClick}>
              <ForwardArrow />
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink className="usepagination__egde" onClick={handleLastClick}>
              {t("pagination.last").toLocaleUpperCase()}
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      )}
    </div>
  );
};

export default UsePagination;
