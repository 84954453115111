import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchWithToken} from "../../../utils";

const tokenFetch = fetchWithToken(fetch);

const REQUEST_LIMIT = 8;

export const fetchTemplates = createAsyncThunk(
  "templates/fetchTemplates",
  async (arg, thunkAPI) => {
    try {
      const page = arg?.page || 0;
      const limit = arg?.limit || REQUEST_LIMIT;
      const query = arg?.query;

      const response = await tokenFetch(
        `/api/v1/templates/?limit=${limit}&offset=${limit * page}${
          query ? `&query=${encodeURIComponent(query)}` : ""
        }`
      );

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const copyTemplates = createAsyncThunk(
  "templates/copyTemplate",
  async (arg, thunkAPI) => {
    try {
      const templateID = arg;

      const response = await tokenFetch(`/api/v1/templates/${templateID}/?action=copy`, {
        method: "PUT",
      });

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTemplatesByGroup = createAsyncThunk(
  "templates/fetchTemplatesByGroup",
  async (arg, thunkAPI) => {
    try {
      const page = arg?.page || 0;
      const limit = arg?.limit || REQUEST_LIMIT;
      const groupID = arg.groupID;

      const response = await tokenFetch(
        `/api/v1/templates/?limit=${limit}&offset=${limit * page}&group_id=${groupID}`
      );

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const moveTemplateToCertainGroup = createAsyncThunk(
  "templates/moveTemplateToCertainGroup",
  async (arg, thunkAPI) => {
    try {
      const response = await tokenFetch(`/api/v1/templates/${arg.templateID}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({group: arg.group}),
      });

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);
