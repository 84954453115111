import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, Redirect} from "react-router-dom";
import {withRouter} from "react-router";
import {
  deleteTemplate,
  saveTemplate,
  loadTemplate,
} from "../../redux/feature/docTemplateEditorV2/actions";
import {createNewDocument} from "../../redux/feature/docTemplateEditorV2";
import {
  getGroups,
  createNewGroup,
  deleteGroup,
  editGroup,
} from "../../redux/feature/groupsV2/actions";
import {
  fetchTemplates,
  fetchTemplatesByGroup,
  moveTemplateToCertainGroup,
  copyTemplates,
} from "../../redux/feature/templatesV2/actions";
import {
  setFileUploadPopUpFile,
  clearFileUploadPopUpFile,
  showPopUp,
} from "../../redux/feature/fileUploadField";
import TemplateList from "../../components/TemplatesList/TemplatesList";
import ConfirmModal from "../../components/ConfrimModal/ConfirmModal";
import DefaultLayout from "../../components/DefaultLayout/DefaultLayout";
import CategoryPanel from "../../components/CategoryPanel/CategoryPanel";
import {Spinner, Button} from "reactstrap";
import {PlusIcon} from "../../components/Icons";
import CreateNewInstanceField from "../../components/CreateNewInstanceField/CreateNewInstanceField";
import {FirstTemplateIcon, NewTemplateIcon} from "../../components/Icons";
import {viewTypes} from "../../components/TemplatesList/TemplatesList";
import UsePagination from "../../components/UsePagination";
import {capitalizeFirstLetter, processPdfFile} from "../../utils";
import {processImageFile} from "../../utils";
import {useTranslation} from "react-i18next";
import {useEvaluateWindowHeight} from "../../hooks/useEvaluateWindowHeight";
import WizardLayout from "../../components/WizardLayout";
import findPageToRequest from "../../utils/findPageToRequest";
import {useNode} from "../../hooks/useNode";
import "./Templates.scss";
import RefWrapper from "../../components/RefWrapper";
import {isMobile, isTablet} from "react-device-detect";
import {useGlobalTheming} from "../../hooks/useGlobalTheming";

import ModalService from "../../modules/modals/services/ModalService";

const NAVBAR_HEIGHT = 67;
const GROUP_PANEL_HEIGHT = 50;
const BOTTOM_BUTTON_FIELD_HEIGHT = 70;
const TOTAL_HEIGHT = NAVBAR_HEIGHT + GROUP_PANEL_HEIGHT + BOTTOM_BUTTON_FIELD_HEIGHT;

const Templates = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isNonDesktop = isMobile || isTablet;
  useGlobalTheming();

  const windowHeight = useEvaluateWindowHeight();

  const templatesList = useSelector((state) => state.templatesV2.items);
  const errorText = useSelector((state) => state.templatesV2.errorText);
  const groups = useSelector((state) => state.groupsV2.results);
  const templateListCount = useSelector((state) => state.templatesV2.count);
  const templateListLimitPerRequest = useSelector((state) => state.templatesV2.limit);
  const currentRequestPage = useSelector((state) => state.templatesV2.page);
  const fileUploadFieldData = useSelector((state) => state.fileUploadField.templates);

  const [view, setView] = useState(localStorage.getItem("ViewType") || viewTypes.LIST);
  const [templateListWithCheckStatus, setTemplateListWithCheckStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewDocumentUploading, setIsNewDocumentUploading] = useState(false);

  //--------------wizard data--------------------------------------------------
  const [
    wizardFirstScenarioFirstTargetNode,
    wizardFirstScenarioFirstTargetRef,
  ] = useNode();
  const [
    wizardFirstScenarioSecondTargetNode,
    wizardFirstScenarioSecondTargetRef,
  ] = useNode();
  const [
    wizardSecondScenarioFirstTargetNode,
    wizardSecondScenarioFirstTargetRef,
  ] = useNode();
  const [
    wizardSecondScenarioSecondTargetNode,
    wizardSecondScenarioSecondTargetRef,
  ] = useNode();

  const [
    wizardFirstScenarioFirstPointerNode,
    wizardFirstScenarioFirstPointerRef,
  ] = useNode();

  const [
    wizardSecondScenarioSecondPointerNode,
    wizardSecondScenarioSecondPointerRef,
  ] = useNode();

  const [
    wizardSecondScenarioThirdPointerNode,
    wizardSecondScenarioThirdPointerRef,
  ] = useNode();

  const [wizardStep, setWizardStep] = useState(0);
  const [wizardScenario, setWizardScenario] = useState(0);
  const [wizardTarget, setWizardTarget] = useState(null);
  const [wizardPointer, setWizardPointer] = useState(null);
  const [wizardRadius, setWizardRadius] = useState("0px");
  const [wizardHelperText, setWizardHelperText] = useState("");
  const [wizardXHelperText, setWizardXHelperText] = useState(0);
  const [wizardYHelperText, setWizardYHelperText] = useState(0);
  const [wizardDisableAllArea, setWizardDisableAllArea] = useState(false);
  //---------------------------------------------------------------------------

  const {t} = useTranslation();
  const [activeGroup, setActiveGroup] = useState("All-Templates");
  const [activeGroupID, setActiveGroupID] = useState("");

  const deleteTemplatesByEditingPopUp = () => {
    const acceptHandler = async (templateListWithCheckStatus) => {
      try {
        for (let template of templateListWithCheckStatus) {
          if (template.checked) {
            await dispatch(deleteTemplate(template.id)).then((data) => {
              if (data.error) {
                alert(t("templates.error-text"));
              }
            });
          }
        }
      } catch {
        alert(t("templates.error-text"));
      } finally {
        setTemplateListWithCheckStatus([]);

        if (activeGroup === "All-Templates") {
          dispatch(
            fetchTemplates({
              page: findPageToRequest(templateListWithCheckStatus, currentRequestPage),
              limit: 8,
            })
          );
        } else {
          dispatch(
            fetchTemplatesByGroup({
              page: findPageToRequest(templateListWithCheckStatus, currentRequestPage),
              limit: 8,
              groupID: activeGroupID,
            })
          );
        }

        ModalService.close();
      }
    };

    for (let template of templateListWithCheckStatus) {
      if (template.checked) {
        ModalService.open(ConfirmModal, {
          headerText: capitalizeFirstLetter(t("templates.delete-header")),
          saveButtonText: t("modal.delete").toLocaleUpperCase(),
          onAccept: () => acceptHandler(templateListWithCheckStatus),
        });
        break;
      }
    }
  };

  const moveTemplatesToCertainGroupHandler = (group) => {
    let promises = [];
    for (let template of templateListWithCheckStatus) {
      if (template.checked) {
        promises.push(
          dispatch(moveTemplateToCertainGroup({group, templateID: template.id}))
        );
      }
    }
    Promise.all(promises)
      .then(() => {
        if (activeGroup === "All-Templates" || group === null) {
          dispatch(
            fetchTemplates({
              page: currentRequestPage,
              limit: 8,
            })
          );
        } else {
          dispatch(
            fetchTemplatesByGroup({
              page: currentRequestPage,
              limit: 8,
              groupID: activeGroupID,
            })
          );
        }
      })
      .catch(() => alert(t("template-editor.error-text")))
      .finally(() => {
        setTemplateListWithCheckStatus([]);
        dispatch(getGroups());
      });
  };

  const duplicateTemplatesByEditingPopUp = () => {
    const acceptHandler = async (templateListWithCheckStatus) => {
      let promises = [];
      for (let template of templateListWithCheckStatus) {
        if (template.checked) {
          promises.push(dispatch(copyTemplates(template.id)));
        }
      }
      Promise.all(promises)
        .then(() => {
          setTemplateListWithCheckStatus([]);
          dispatch(fetchTemplates({page: currentRequestPage, limit: 8}));
          ModalService.close();
        })
        .catch(() => alert(t("templates.error-text")));
    };

    for (let template of templateListWithCheckStatus) {
      if (template.checked) {
        ModalService.open(ConfirmModal, {
          headerText: capitalizeFirstLetter(t("templates.duplicate-header")),
          saveButtonText: t("modal.duplicate").toLocaleUpperCase(),
          onAccept: () => acceptHandler(templateListWithCheckStatus),
        });
        break;
      }
    }
  };

  const acceptHandler = async (id) => {
    try {
      await dispatch(deleteTemplate(id)).then((data) => {
        if (data.error) {
          alert(t("templates.error-text"));
        }
      });
    } catch {
      alert(t("templates.error-text"));
    } finally {
      if (activeGroup === "All-Templates") {
        //проверяем удаляли мы последний шаблон
        if (templatesList.length === 1) {
          dispatch(
            fetchTemplates({
              page: currentRequestPage - 1 > 0 ? currentRequestPage - 1 : 0,
              limit: 8,
            })
          );
        } else {
          dispatch(fetchTemplates({page: currentRequestPage, limit: 8}));
        }
      } else {
        //проверяем удаляли мы последний шаблон на странице пагинации
        if (templatesList.length === 1) {
          dispatch(
            fetchTemplatesByGroup({
              page: currentRequestPage - 1 > 0 ? currentRequestPage - 1 : 0,
              limit: 8,
              groupID: activeGroupID,
            })
          );
        } else {
          dispatch(
            fetchTemplatesByGroup({
              page: currentRequestPage,
              limit: 8,
              groupID: activeGroupID,
            })
          );
        }
      }

      ModalService.close();
    }
  };

  const editTemplateHandler = (template) => {
    const {id} = template;
    history.push(`/edit/${id}`);
  };

  const deleteTemplateHandler = (template) => {
    const {id} = template;

    ModalService.open(ConfirmModal, {
      headerText: capitalizeFirstLetter(t("templates.delete-header")),
      saveButtonText: t("modal.delete").toLocaleUpperCase(),
      onAccept: () => acceptHandler(id),
    });
  };

  const changePageHandler = (url) => {
    history.push(url);
  };

  const uploadNewFile = (file) => {
    dispatch(setFileUploadPopUpFile({file, page: "templates"}));
    //------wizard logic---------------------------------------
    if (wizardScenario === 0) {
      setWizardStep(1);
    }
    //----------------------------------------------------------
  };

  const recognizeByIDHandler = (templateID) => {
    //TODO: remake it via redux
    localStorage.setItem("recognizeByTemplateID", templateID);
    history.push("/recognition/results");
  };

  const createNewDocumentHandler = () => {
    const file = fileUploadFieldData.file;

    //------wizard logic---------------------------------------
    setWizardStep(2);
    //----------------------------------------------------------

    const handleFileIsReady = (...fileProperties) => {
      dispatch(
        createNewDocument({
          image: fileProperties[0],
          imageBlob: fileProperties[1],
          fileName: fileProperties[2],
        })
      );
      // формируем пустой документ для сохранения и получения ID
      const document = {
        width: fileProperties[0].width,
        height: fileProperties[0].height,
        image: fileProperties[0],
        imageBlob: fileProperties[1],
        fileName: fileProperties[2],
        templateName: "Template name #",
        group: null,
      };
      // сохраняем пустой документ и получаем ID
      setIsNewDocumentUploading(true);
      dispatch(saveTemplate({document, areas: []})).then((data) => {
        if (!data.error) {
          //нужно для поддержки повторяющихся имен шаблонов. Т.е. сохраняем шаблон,
          //потом получаем его же и сохраняем в стейт.
          dispatch(loadTemplate(data.payload.id)).then(() => {
            if (!data.error) {
              //clear fileAploadFiledData
              dispatch(clearFileUploadPopUpFile({page: "templates"}));
              dispatch(showPopUp({status: false, page: "templates"}));
              setIsNewDocumentUploading(false);
              history.push("/new");
            } else {
              alert("Something is going wrong. Try later.");
            }
          });
        } else {
          alert("Something is going wrong. Try later.");
        }
      });
    };

    if (file.type === "application/pdf") {
      processPdfFile(file, handleFileIsReady);
    }
    if (file.type.includes("image")) {
      processImageFile(file, handleFileIsReady);
    }
  };

  const viewChooseHandler = (view) => {
    setView(view);
    localStorage.setItem("ViewType", view);
  };

  const deleteGroupWithConfirmation = (group) => {
    const acceptDeletion = () => {
      dispatch(deleteGroup(group.id))
        .then((data) => {
          if (data.error) {
            throw new Error("Can't delete group");
          }

          dispatch(getGroups()).then((data) => {
            if (data.error) {
              throw new Error("Can't upload groups");
            }
          });
        })
        .catch(() => alert(t("template-editor.error-text")))
        .finally(() => ModalService.close());
    };

    ModalService.open(ConfirmModal, {
      headerText: capitalizeFirstLetter(t("delete-group-confim-modal.header")),
      paragraphText: capitalizeFirstLetter(t("delete-group-confim-modal.paragraph-text")),
      saveButtonText: t("modal.delete").toLocaleUpperCase(),
      onAccept: acceptDeletion,
    });
  };

  const deleteGroupHandler = (e, group) => {
    e.stopPropagation();

    if (group.templates.length) {
      deleteGroupWithConfirmation(group);
      return;
    }

    dispatch(deleteGroup(group.id))
      .then(() => dispatch(getGroups()))
      .catch(() => alert(t("template-editor.error-text")));
  };

  const editGroupHandler = async (groupID, name, isInitName) => {
    return await dispatch(editGroup({groupID, name, isInitName}));
  };

  const loadTemplatesByGroup = (group) => {
    setIsLoading(true);

    dispatch(
      fetchTemplatesByGroup({
        page: 0,
        limit: 8,
        groupID: group.name === null ? null : group.id,
      })
    ).then(() => setIsLoading(false));
  };

  const loadAllTemplates = () => {
    setIsLoading(true);
    dispatch(fetchTemplates({page: 0, limit: 8})).then(() => setIsLoading(false));
  };

  const createNewGroupHandler = async (groupName) => {
    try {
      return dispatch(createNewGroup(groupName));
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    dispatch(fetchTemplates({page: 0, limit: 8})).then(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch, templatesList]);

  useEffect(() => {
    //wizard
    if (!localStorage.getItem("parsio.skipWizardStatus")) {
      dispatch(setFileUploadPopUpFile({file: null, page: "templates"}));
      templatesList.length ? setWizardScenario(1) : setWizardScenario(0);
    }
  }, [dispatch, setWizardScenario, templatesList.length]);

  useEffect(() => {
    switch (wizardStep) {
      case 0:
        if (wizardFirstScenarioFirstTargetNode || wizardSecondScenarioFirstTargetNode) {
          setWizardTarget(
            wizardScenario === 0
              ? wizardFirstScenarioFirstTargetNode
              : wizardSecondScenarioFirstTargetNode
          );
          setWizardPointer(
            wizardScenario === 0
              ? wizardFirstScenarioFirstPointerNode
              : wizardSecondScenarioFirstTargetNode
          );
          setWizardRadius(wizardScenario === 0 ? "240px" : "100px");
          setWizardHelperText(
            wizardScenario === 0
              ? [t("wizard.templates-page-text1")]
              : [t("wizard.templates-page-text2")]
          );
          setWizardXHelperText(59);
          setWizardYHelperText(wizardScenario === 0 ? 65 : 80);
        }
        break;
      case 1:
        if (wizardFirstScenarioSecondTargetNode || wizardSecondScenarioSecondTargetNode) {
          setWizardTarget(
            wizardScenario === 0
              ? wizardFirstScenarioSecondTargetNode
              : wizardSecondScenarioSecondTargetNode
          );
          setWizardPointer(
            wizardScenario === 0
              ? wizardFirstScenarioSecondTargetNode
              : wizardSecondScenarioSecondPointerNode
          );
          setWizardRadius(wizardScenario === 0 ? "50px" : "300px");
          setWizardHelperText(
            wizardScenario === 0
              ? [t("wizard.templates-page-text3")]
              : [t("wizard.templates-page-text4")]
          );
          setWizardXHelperText(65);
          setWizardYHelperText(wizardScenario === 0 ? 75 : 70);
        }
        break;
      case 2:
        setWizardDisableAllArea(true);
        break;
      default:
        break;
    }

    //for second scenario when you need to show pointer on DONE button
    if (wizardSecondScenarioThirdPointerNode && wizardStep !== 2) {
      setWizardPointer(wizardSecondScenarioThirdPointerNode);
    }
  }, [
    wizardStep,
    wizardFirstScenarioFirstTargetNode,
    wizardFirstScenarioFirstPointerNode,
    wizardFirstScenarioSecondTargetNode,
    wizardSecondScenarioFirstTargetNode,
    wizardSecondScenarioSecondTargetNode,
    wizardSecondScenarioSecondPointerNode,
    wizardSecondScenarioThirdPointerNode,
    wizardScenario,
    templatesList.length,
    t,
  ]);

  if (isNonDesktop) {
    return <Redirect to="/recognition/results" />;
  }

  return (
    <WizardLayout
      targetNode={wizardTarget}
      pointerNode={wizardPointer}
      radius={wizardRadius}
      xHelperText={wizardXHelperText}
      yHelperText={wizardYHelperText}
      helperTextList={wizardHelperText}
      disableAllArea={wizardDisableAllArea}
    >
      <DefaultLayout>
        {isLoading && (
          <div className="spinner__container">
            <Spinner />
          </div>
        )}
        {!isLoading && errorText && (
          <div className="spinner__container">
            <p>{t("results.server-error-text")}</p>
          </div>
        )}
        <CategoryPanel
          activeGroup={activeGroup}
          groups={groups}
          view={view}
          onViewChoose={viewChooseHandler}
          onLoadAllData={loadAllTemplates}
          onLoadDataByGroup={loadTemplatesByGroup}
          onSetActiveGroup={setActiveGroup}
          onSetActiveGroupID={setActiveGroupID}
        />
        {!isLoading &&
          !errorText &&
          !templatesList.length &&
          activeGroup === "All-Templates" && (
            <CreateNewInstanceField
              isStart
              ref={{
                wizardFirstScenarioFirstTargetRef,
                wizardFirstScenarioSecondTargetRef,
                wizardFirstScenarioFirstPointerRef,
              }}
              file={fileUploadFieldData.file}
              isNewDocumentUploading={isNewDocumentUploading}
              icon={<FirstTemplateIcon />}
              headerText={capitalizeFirstLetter(t("templates.start-header"))}
              paragraphText={capitalizeFirstLetter(t("templates.add-first-document"))}
              buttonText={t("modal.choose-file").toLocaleUpperCase()}
              beforeButtonText={t("results.drag-n-drop-text")}
              onCreateNewDocument={createNewDocumentHandler}
              onSetFile={(file) => uploadNewFile(file)}
              onClearFile={() => dispatch(clearFileUploadPopUpFile({page: "templates"}))}
            />
          )}
        {!isLoading &&
          !errorText &&
          !templatesList.length &&
          activeGroup !== "All-Templates" && (
            <>
              <div className="no-data__container">No templates.</div>
              <Button
                className="templates__button"
                color="primary"
                size="sm"
                onClick={() => dispatch(showPopUp({status: true, page: "templates"}))}
              >
                <PlusIcon className="mb-1 mr-2" width="12px" height="12px" fill="white" />
                {t("templates.new-template").toLocaleUpperCase()}
              </Button>
              {fileUploadFieldData.isPopUpOpen && (
                <CreateNewInstanceField
                  icon={<NewTemplateIcon />}
                  file={fileUploadFieldData.file}
                  isNewDocumentUploading={isNewDocumentUploading}
                  headerText={capitalizeFirstLetter(t("templates.add-new-document"))}
                  buttonText={t("modal.choose-file").toLocaleUpperCase()}
                  beforeButtonText={t("results.drag-n-drop-text")}
                  isPopUp
                  onCreateNewDocument={createNewDocumentHandler}
                  onSetFile={(file) =>
                    dispatch(setFileUploadPopUpFile({file, page: "templates"}))
                  }
                  onClearFile={() =>
                    dispatch(clearFileUploadPopUpFile({page: "templates"}))
                  }
                  onClosePopUp={() =>
                    dispatch(showPopUp({status: false, page: "templates"}))
                  }
                />
              )}
            </>
          )}
        {fileUploadFieldData.isPopUpOpen && (
          <CreateNewInstanceField
            icon={<NewTemplateIcon />}
            ref={{
              wizardSecondScenarioSecondTargetRef,
              wizardSecondScenarioSecondPointerRef,
              wizardSecondScenarioThirdPointerRef,
            }}
            file={fileUploadFieldData.file}
            isNewDocumentUploading={isNewDocumentUploading}
            headerText={capitalizeFirstLetter(t("templates.add-new-document"))}
            buttonText={t("modal.choose-file").toLocaleUpperCase()}
            beforeButtonText={t("results.drag-n-drop-text")}
            isPopUp
            onCreateNewDocument={createNewDocumentHandler}
            onSetFile={(file) => uploadNewFile(file)}
            onClearFile={() => dispatch(clearFileUploadPopUpFile({page: "templates"}))}
            onClosePopUp={() => dispatch(showPopUp({status: false, page: "templates"}))}
          />
        )}
        {!isLoading && !errorText && !!templatesList.length && (
          <>
            <UsePagination
              pageCount={Math.ceil(templateListCount / templateListLimitPerRequest) || 0}
              currentRequestPage={currentRequestPage}
              cb={
                activeGroup === "All-Templates"
                  ? (page) => dispatch(fetchTemplates({page, limit: 8}))
                  : (page) =>
                      dispatch(
                        fetchTemplatesByGroup({page, limit: 8, groupID: activeGroupID})
                      )
              }
              className="templates__pagination pt-3"
              style={
                view === viewTypes.MODULE
                  ? {maxHeight: windowHeight - TOTAL_HEIGHT}
                  : null
              }
            >
              <TemplateList
                view={view}
                templatesList={templatesList}
                groups={groups}
                currentRequestPage={currentRequestPage}
                templateListWithCheckStatus={templateListWithCheckStatus}
                onGetGroups={() => dispatch(getGroups())}
                onSetTemplateListWithCheck={setTemplateListWithCheckStatus}
                onDelete={deleteTemplateHandler}
                onEdit={editTemplateHandler}
                onPageChange={changePageHandler}
                onRecognizeById={recognizeByIDHandler}
                onDeleteByEditingPopUp={deleteTemplatesByEditingPopUp}
                onDuplicateByEditingPopUp={duplicateTemplatesByEditingPopUp}
                onMoveTemplatesToCertainGroup={moveTemplatesToCertainGroupHandler}
                onCreateNewGroup={createNewGroupHandler}
                onDeleteGroup={deleteGroupHandler}
                onEditGroup={editGroupHandler}
              />
            </UsePagination>
            <RefWrapper
              className={"templates__button"}
              ref={wizardSecondScenarioFirstTargetRef}
            >
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  setWizardStep(1);
                  dispatch(showPopUp({status: true, page: "templates"}));
                }}
              >
                <PlusIcon className="mb-1 mr-2" width="12px" height="12px" fill="white" />
                {t("templates.new-template").toLocaleUpperCase()}
              </Button>
            </RefWrapper>
          </>
        )}
      </DefaultLayout>
    </WizardLayout>
  );
};

export default withRouter(Templates);
